<!-- =========================================================================================
  File Name: .vue
  Description: eCommerce Item Detail page
========================================================================================== -->

<template>
  <div id="item-detail-page">
    <div class="vx-row">
      <div class="vx-col lg:w-1/1 w-full">
        <vx-card>
          <div>
            <p>
              <b></b>
            </p>
          </div>
        </vx-card>
      </div>
    </div>
    <br />
    <br />
    <div class="knowledge-base-jumbotron color-red">
      <div class="knowledge-base-jumbotron-content lg:p-32 md:p-24 sm:p-16 p-8 rounded-lg mb-base">
        <h1 class="mb-1 text-white">Book an appointment</h1>
        <p
          class="text-white"
        >Our general services include consultations and packages that meet you stage of life needs. Our goal is always proactive, preventative care for the best experience.</p>
        <!-- vs-input
          icon-no-border
          placeholder="Search Treatment or condition"
          v-model="treatmentSearchQuery"
          icon-pack="feather"
          icon="icon-search"
          size="large"
          class="w-full mt-6"
        / -->
      </div>
    </div>

    <div class="vx-row">
      <div
        class="vx-col w-full md:w-1/3 sm:w-1/2 mb-base"
        v-for="item in filteredKB"
        :key="item.carePlanId"  @click="$router.push(item.url).catch(() => {})"

      >


        <vx-card class="text-center cursor-pointer">
          <img :src="item.image" height="120" class="mx-auto mb-4" />
          <!-- h5 class="mb-2"><u><a :href="item.url">{{ item.title}}</a></u><feather-icon class="mb-2" icon="ChevronRightIcon" svgClasses="w-4 h-4"/></h5 -->
          <h5 class="mb-2">{{ item.label }}</h5>
          <small>{{ item.description }}</small>
          <!-- small v-for="ptype in item.patientType" :key="ptype.value">{{ ptype }}</small -->
        </vx-card>


                  <!--vx-card>
                    <div slot="no-body">
                        <img :src="item.image" alt="content-img" class="responsive card-img-top">
                    </div>
                    <h5 class="mb-2">{{ item.descriptione }}</h5>
                    <p class="text-grey">{{ item.label }}</p>
                    <div class="flex justify-between flex-wrap">
                        <vs-button class="mt-4 mr-2 shadow-lg" type="gradient" color="#7367F0" gradient-color-secondary="#CE9FFC">Download</vs-button>
                        <vs-button class="mt-4" type="border" color="#b9b9b9">View All</vs-button>
                    </div>
                </vx-card -->



        </div>

    </div>



        <!-- div class="vx-row">
      <div
        class="vx-col w-full md:w-1/3 sm:w-1/2 mb-base"
        v-for="item in filteredKB"
        :key="item.id"  @click="$router.push(item.url).catch(() => {})"

      >


                  <vx-card>
                    <div slot="no-body">
                        <img :src="item.image" alt="content-img" class="responsive card-img-top">
                    </div>
                    <h5 class="mb-2">{{ item.description }}</h5>
                    <p class="text-grey">{{ item.label }}</p>
                    <div class="flex justify-between flex-wrap">
                        <vs-button class="mt-4 mr-2 shadow-lg" type="gradient" color="#7367F0" gradient-color-secondary="#CE9FFC">Download</vs-button>
                        <vs-button class="mt-4" type="border" color="#b9b9b9">View All</vs-button>
                    </div>
                </vx-card>

        </div>

    </div -->


  </div>
</template>
<script>
//import axios from '@/axios.js';

import Prism from "vue-prism-component";
import moduleCarePlan from '@/store/careplan/moduleCarePlan.js'

export default {
  components: {
    Prism
  },
  data() {
    return {
      selectedOrgId: null,
      treatmentSearchQuery: "",
      //Broken Tooth,Emergency, Extraction,Implant Consultation, Teeth Dentistry
      bannerImg:
        "",
        //https://healthminder-dental-website.s3.ap-southeast-1.amazonaws.com/Background%20%26%20Images/HMDentalCare_TreatmentsTopImg.png
      error_occured: false,
      allPackages: null,
      error_msg: ""
    };
  },
  computed: {
    documentCenterData() {
      console.log(this.$store.state.careplan.allCarePlansByOrgIdList);

      //return this.$store.state.careplan.maternityServiceOptions;
      return this.allPackages;
    },
    filteredKB() {
      //return this.$store.state.eCommerce.wishList.slice().reverse()
      //$store.state.careplan.maternityAppointmentTypeOptions
      return this.$store.state.careplan.allCarePlansByOrgIdList;
      //this.$store.state.careplan.allCarePlansByOrgIdList.filter((item) => item.label.toLowerCase().includes(this.treatmentSearchQuery.toLowerCase()));




      //return this.allPackages.filter((item) => item.label.toLowerCase().includes(this.treatmentSearchQuery.toLowerCase()));
     /* return this.allPackages.filter(item =>
        item.name
          .toLowerCase()
          .includes(this.treatmentSearchQuery.toLowerCase())
      );*/
    }
  },
  methods: {

  },

  created() {
//http://localhost:80packg/filterPackg?

 this.selectedOrgId = parseInt(this.$route.params.org_id);
console.log("--------------------- START CREATED --------------------- ");

   const payload = {
      acceptingNewPatient: "any",
      affiliation:"any",
      ageGroup:"any",
      bookOnline:"any",
      distance:-1,
      doctorGender:"any",
      hospitalName:"any",
      inputLatitude:-1,
      inputLongitude:-1,
      language: "any",
      organizationId: this.selectedOrgId,
      providerGender: "any",
      speciality: "any",
      walkInPatient:"any"

    }

        if (!moduleCarePlan.isRegistered) {
      this.$store.registerModule('moduleCarePlan', moduleCarePlan)
      moduleCarePlan.isRegistered = true
    }

console.log(payload);

//this.$store.dispatch("moduleCarePlan/fetchCarePlansByOrgId", payload).catch(err => { console.error(err) });

    this.$store.dispatch("moduleCarePlan/fetchCarePlansByOrgId", payload)
            .then((response) => {
               console.log("--------------------- RESPONSE CREATED --------------------- ");
          console.log(response.data);
this.allPackages = response.data.packgs;
        }).catch(err => { console.error(err) });

 console.log("--------------------- END CREATED --------------------- ");
  }
};
</script>

<style lang="scss">
.knowledge-base-jumbotron-content {
  background-color: #C49152,
;
  //background-image: url("https://healthminder-dental-website.s3.ap-southeast-1.amazonaws.com/Background%20%26%20Images/HMDentalCare_TreatmentsTopImg.png");
  background-size: cover;
}
</style>
